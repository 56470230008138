<template>
    <div class="search-ctn">
        <div class="search-options" >
            <a-dropdown size="small">
                <a-menu size="small" slot="overlay" @click="handleTimeRangeClick">
                   <!--  <a-menu-item key="1"> 时间不限 </a-menu-item>
                    <a-menu-item key="2"> 一日内 </a-menu-item>
                    <a-menu-item key="3"> 一周内 </a-menu-item>
                    <a-menu-item key="4"> 一年内 </a-menu-item> -->
                    <a-menu-item  size="small" v-for="(option, index) in timeRangeOptionList" :key="option.value"> {{ option.label }} </a-menu-item>
                </a-menu>
                <a-button size="small" style="margin-left: 8px"> {{  timeRangeText }} <a-icon type="down" /> </a-button>
            </a-dropdown>
            <a-dropdown style="margin-left: 32px;" size="small">
                <a-menu size="small" slot="overlay" @click="handleTextRangeChange">
                   <!--  <a-menu-item  key="0"> 范围不限 </a-menu-item>
                    <a-menu-item key="1"> 仅标题 </a-menu-item>
                    <a-menu-item key="2"> 摘要和内容 </a-menu-item> -->
                    <a-menu-item size="small" v-for="(option ,index) in textRangeOptionList" :key="option.value"> {{  option.label  }}</a-menu-item>
                </a-menu>
                <a-button size="small" style="margin-left: 8px"> {{  textRangeText }} <a-icon type="down" /> </a-button>
            </a-dropdown>
        </div>
        <div class="list-cnt">
            <a-spin :spinning="isLoading" tip="正在搜索中">
                <div v-if="list.length === 0" style="padding: 50px; min-height: 400px;">
                    <a-empty description="暂无数据">

                    </a-empty>
                </div>
                <div v-for="(post, index) in list" >
                    <SearchResItem :postData="post" :keyWord="searchValue"></SearchResItem>
                </div>

                <div  class="pagination-ctn default-pagination">
                        <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total"
                            show-quick-jumper hideOnSinglePage style="margin: 20px;"/>
                    </div>
            </a-spin>

        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue-demi';
import { getQueryPaper } from '@/api/researchGroupPaperManage.js'
import dayjs from 'dayjs';
import SearchResItem from './components/searchResItem.vue';
const timeRageOption = Object.freeze({
    '1': '时间不限',
    '2': '一日内',
    '3': '一周内',
    '4': '一个月内',
    '5': '一年内'
})

const textRangeOption = Object.freeze({
    '0': '范围不限',
    '1': '仅标题',
    '2': '摘要和内容'
})

export default {
    props: {

    },
    components: {
        SearchResItem
    },
    setup(props, context) {
        const { $route, $router } = context.root;

        const searchValue = ref($route.query.query);

        const timeStamp = ref($route.query.ref);

       /*  watch(() => $route, (val) => {
            searchValue.value = val.query.query;
        }) */

        const timeRange = ref($route.query.tir ?  $route.query.tir: '1')    // 时间范围

        // 时间范围名称
        const timeRangeText = computed(() => {
            const textRes = timeRageOption[timeRange.value];
            return textRes ? textRes : ''
        })
        // 时间范围改变
        const handleTimeRangeClick = (val) => {
            timeRange.value = val.key;
            $router.replace({
                name: 'bbsSearch',
                query: {
                    ...$route.query,
                    tir: val.key
                }
            })
            current.value = 1;
            handlePageChange();
        }

        // 搜索范围
        const textRange = ref($route.query.tr ?  $route.query.tr : '0');

        // 搜索范围名称
        const textRangeText = computed(() => {
            const textRes = textRangeOption[textRange.value];
            return textRes ? textRes : ''
        })

        // 搜索范围值改变
        const handleTextRangeChange = (val) => {
            textRange.value = val.key;
            $router.replace({
                name: 'bbsSearch',
                query: {
                    ...$route.query,
                    tr: val.key
                }
            })
            handlePageChange()
        }


        onMounted(() => {
            document.title = `${$route.query.query} - 搜索结果`;
            handlePageChange();
        })

        // 时间范围选择列表
        const timeRangeOptionList = computed(() => {
            const resList = Object.keys(timeRageOption).map((key) => {
                return {
                    label: timeRageOption[key],
                    value: key
                }
            })
            return resList.filter(option => option.value != timeRange.value);
        })

        // 搜索范围选择列表
        const textRangeOptionList = computed(() => {
            const resList = Object.keys(textRangeOption).map((key) => {
                return {
                    label: textRangeOption[key],
                    value: key
                }
            })
            return resList.filter(option => option.value != textRange.value)
        })

        // 当前页数
        const current = ref(1);

        // 一页数量
        const pageSize = ref(10);

        // 总数
        const total = ref(0);

        // 是否正在搜索中
        const isLoading = ref(false);

        // 列表值
        const list = ref([]);

        // 搜索开始时间
        const searchBeginTime = computed(() => {
            // 时间不限
            if(timeRange.value  == '1') {
                return undefined
            }
            // 一日内
            else if(timeRange.value == '2') {
                const now = new dayjs();
                return now.subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            // 一周内
            else if(timeRange.value == '3') {
                const now = new dayjs();
                return now.subtract(1, 'week').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            // 一个月内
            else if(timeRange.value == '4') {
                const now = new dayjs();
                return now.subtract(1, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            // 一年内
            else if(timeRange.value == '5') {
                const now = new dayjs();
                return now.subtract(1, 'year').startOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            return undefined
        })

        // 搜索结束时间
        const searchEndTime = computed(() => {
            if(timeRange.value == '1') {
                return undefined
            }
            else {
                const now = new dayjs();
                return now.endOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
        })

        // 页数改变
        const handlePageChange = async () => {
            isLoading.value = true;
            const res = await getQueryPaper({
                searchType: textRange.value,
                searchValue: searchValue.value,
                pageNo: current.value,
                pageSize: pageSize.value,
                searchBeginTime: searchBeginTime.value,
                searchEndTime: searchEndTime.value
            });
            if(res.success) {
                total.value = res.data.totalRows;
                list.value = res.data.rows;
            }
            isLoading.value = false;
        }

       

        
        return {
            timeRangeText,
            handleTimeRangeClick,
            textRangeText,
            handleTextRangeChange,
            timeRangeOptionList,
            textRangeOptionList,
            current,
            pageSize,
            total,
            isLoading,
            handlePageChange,
            list,
            searchValue
        }
    }
}
</script>

<style lang="less" scoped>
.search-ctn {
    margin-top: 20px;
    margin-bottom: 40px;
    background: #fff;
    border: 1px solid #ebebeb;
    max-width: 800px;
    margin: 20px auto 50px auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04);
    .search-options {
        padding: 12px 0px;
        border-bottom: 1px solid #ebebeb;
        display: flex;
        align-items: center;
    }
}
</style>