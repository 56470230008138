<!-- 单个帖子列表内容 -->
<template>
    <div class="post-item-ctn" @click="handlePostItemClick">
        <div class="post-item-left-ctn">
            <div class="post-item-header-ctn" @click.stop="() => { }">
              <!--   <a class="avatar-link" :href="getUserHref" target="_blank">
                    <DefaultAvatar :name="postData.userName" :url="postData.avatar" :size="40"></DefaultAvatar>
                </a>
                <div class="text-ctn">
                    <a :href="getUserHref" target="_blank" class="user-name-ctn">
                        <div class="user-name">
                            {{ postData.userName }}
                        </div>
                    </a>
                    <div class="publish-date" style="font-size: 12px;">
                        发布于 {{ postData.publishedTime }}
                    </div>
                </div> -->
                <a-popover >
                    <template slot="content">
                        <div style="display: flex;">
                            <a class="avatar-link" :href="getUserHref" target="_blank">
                                <DefaultAvatar :name="postData.userName" :url="postData.userAvatar" :size="48"></DefaultAvatar>
                            </a>
                            <div class="text-ctn" style="margin-left: 12px; width: 140px; word-break: break-all;">
                                <a :href="getUserHref" target="_blank" class="user-name-ctn">
                                    <div class="user-name">
                                        {{ postData.userName }}
                                    </div>
                                </a>
                                <div class="publish-date" style="font-size: 12px;">
                                   {{ postData.biography }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <a :href="getUserHref" target="_blank" class="user-name-ctn">
                        {{ postData.userName }}
                    </a>
                </a-popover>
                <div class="header-gap" style="background: rgba(0, 0, 0, 0.45); margin: auto 12px; width: 1px; height: 16px;">
                </div>
                <span v-if="postData.groupId != 0 && postData.groupName" style=" background: #31B588; color: #fff; font-size: 12px; padding:2px 4px;border-radius: 4px; height: fit-content; "> {{  postData.groupName  }} </span>
                <div v-if="postData.groupId != 0 && postData.groupName" class="header-gap" style="background: rgba(0, 0, 0, 0.45); margin: auto 12px; width: 1px; height: 16px;">
                </div>
                <div class="publish-date" style="font-size: 12px; margin: auto 0;">
                        发布于 {{ postData.publishedTime }}
                    </div>
            </div>
            <div class="post-item-title" >
                <a  :href="getPostHref" target="_blank" :title="postData.title" @click.stop="() => {}">
                    <span v-html="getHightLightTitle">

                    </span>
                </a>
               <!--  <a>
                    
                </a> -->
            </div>
            <div class="post-item-description" >
                <a :href="getPostHref" target="_blank" :title="postData.summary" @click.stop="() => {}">
                   <!--  脑血管造影（DSA）在神经科临床工作中的应用已越来越广泛，更全面准确地判断正常血管和病变血管的部位和程度已成为神经科医师的基本技能之一。今天为大家整理的是脑血管造影阅片方面的内容，希望有所帮助。 -->
                  <span v-html="getHightLightSummary"></span>
                </a>
            </div>
            <div class="post-item-operation" @click.stop="() => {}">
               <!--  <a-button v-if="index % 3 === 0" type="plain" class="comment-btn"> <a-icon type="edit" /> 评论 </a-button> -->
                
                <div class="operation-ctn" @click.stop="">
                    <a-tooltip :title="postData.hasLike === 1 ? '取消点赞' : '点赞'">
                        <svg-icon @click.stop="handleToggleLike" name="thumbs-up" :class="['operation-icon', postData.hasLike == 1 ? 'thumbs-up-active' : 'thumbs-up-inactive', getThumbsClass]"></svg-icon>
                    </a-tooltip>
                    <span :class="['operation-text', postData.hasLike === 1  ? 'operation-text-active' : '' ]"> 喜欢 <span> ({{ postData.likeCount }}) </span> </span>
                </div>
                <div  class="operation-ctn comment-operation" @click.stop="handleCommentClick">
                    <svg-icon name="chat-1-fill" :class="['operation-icon', 'chat-1-fill']"></svg-icon>
                    <span :class="['operation-text']"> {{ postData.commitCount }} 条评论 </span>
                </div>
                <div class="operation-ctn"  >
                    <a-tooltip placement="top" :title="postData.hasCollect == 1 ? '取消收藏' : '收藏'">
                        <svg-icon name="bbs-star" :class="['operation-icon', 'chat-1-fill',  postData.hasCollect == 1 ? 'collected-icon' : ' uncollocted-icon']" @click.stop="toggleCollect"></svg-icon>
                    </a-tooltip>
                    <span :class="['operation-text']"> {{ postData.hasCollect == 1 ? '取消收藏' : '收藏'  }} </span>
                </div>
                <!-- <div class="operation-ctn setting-operation" title="设置" v-if="isSelf" >
                    <a-popover v-model="settingVisible" trigger="click">
                        <div style="display: flex; align-items: center;">
                             <svg-icon name="settings-4-fill" :class="['operation-icon', 'chat-1-fill']"></svg-icon>
                         <span :class="['operation-text']"> 设置 </span>
                        </div>
                        <template slot="content">
                            <a-spin :spinning="isSubmittingSetting">
                                <div class="setting-item">
                                    <div class="setting-item-inner" @click="setVisibleRange(1)">
                                        <div style="width: 20px;">
                                            <a-icon type="check" v-if="postData.visibleRange == 1" />
                                        </div> <span style="margin-left: 6px ;">私密</span>
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-item-inner" @click="setVisibleRange(2)">
                                        <div style="width: 20px;"  >
                                        <a-icon type="check" v-if="postData.visibleRange != 1"/>
                                        </div> <span style="margin-left: 6px ;">公开</span> 
                                    </div>
                                </div>
                                <div class="setting-item">
                                    <div class="setting-item-inner" @click="setAllowedComment">
                                        <div style="width: 20px;" >
                                        <a-icon type="check" v-if="postData.allowedComment == 1"/> 
                                        </div>
                                        <span style="margin-left: 6px ;">允许评论</span>
                                    </div> 
                                </div>
                            </a-spin>
                        </template>
                    </a-popover>
                </div>
                <div class="operation-ctn delete-operation" title="删除" v-if="isSelf" @click="handleDelete">
                    <svg-icon name="delete-bin-5-fill" :class="['operation-icon', 'chat-1-fill']"></svg-icon>
                    <span :class="['operation-text']"> 删除 </span>
                </div> -->
            </div>
        </div>
        <div v-show="postData.coverPathCode" class="post-item-right-ctn">
            <img class="post-item-cover" :src="postData.coverPathCode" />
        </div>
    </div>
</template>

<script>
import DefaultAvatar from '@/components/defaultAvatar.vue';
import { deletePaper, likePost, unLikePost, collectPost, unCollectPost,setPostVisibleRange, setPostIsAllowedComment } from '@/api/paperManage.js';
import { computed, h, ref } from 'vue-demi';
import { message, Modal } from 'ant-design-vue';
import debounce from 'lodash/debounce';
import store from '@/store';
export default {
    props: {
        index: {
            type: Number,
            default: 0
        },
        showCollection: {
            default: false
        },
        showSetting: {
            default: false
        },
        showDelete: {
            default: false
        },
        isSelf: {
            default: false
        },
        postData: {
            default: () => ({})
        },
        keyWord: {
            default: ''
        }
    },
    setup(props, context) {

        const { $route, $router } = context.root;
        // 处理删除
        const handleDelete = () => {
            Modal.confirm({
                title: '提示',
                content: h('span', { }, [
                    '是否确认删除文章',
                    h('span', { style: {  color: '#1890ff' } }, props.postData.title),
                    '? 删除后无法恢复'
                ] ),
                onOk: async () => {
                    const formData = new FormData();
                    formData.append('paperid',props.postData.paperId )
                    const res =  await deletePaper(formData)
                    if(res.success) {
                        message.success('成功删除');
                        context.emit('onDelete', props.index);
                    }
                }
            })
        }
        
        const isRecentLike = ref(false);

        // 获取
        const getThumbsClass = computed(() => {
            return isRecentLike.value  ?  "recent-like" : '';
        })

        const getUserHref = computed(() => {
            let realId = props.postData.userId;
            if( props.postData.userId == store.state.userInfo.userId) {
                realId = 0;
            }
            const href = $router.resolve({
                name: "bbsUser",
                params: {
                    id: realId
                }
            });
            return href.href;
        })
    

        const getPostHref = computed(() => {
            const href = $router.resolve({
                name: "bbsPost",
                params: {
                    id: props.postData.paperId
                }
            });
            return href.href;
        })

        const handlePostItemClick = () => {
            const href = $router.resolve({
                name: "bbsPost",
                params: {
                    id: props.postData.paperId
                }
            });
            window.open(href.href, '_blank')
        }

        const isLikeSubmiting = ref(false);     // 是否正在喜欢或取消喜欢

        // 点击喜欢/不喜欢
        const handleToggleLike = debounce(async () => {
            console.log(props.postData)
            if(props.postData.hasLike === undefined || props.postData.hasLike === 0) {
                return 
            }
            if(isLikeSubmiting.value) {
                return ;
            }
            isLikeSubmiting.value = true;
            const isLike = props.postData.hasLike == 1;
            isRecentLike.value= false;
            if(isLike) {
                const formData = new FormData();
                formData.append('paperid', props.postData.paperId)
                const res = await unLikePost(formData);
                if(res.success) {
                    message.info('已取消点赞')
                    props.postData.hasLike = 2;
                    props.postData.likeCount = props.postData.likeCount - 1;
                }
            }
            else {
                const formData = new FormData();
                formData.append('paperid', props.postData.paperId)
                const res = await likePost(formData);
                if(res.success) {
                    message.success('已点赞')
                    props.postData.hasLike = 1;
                    props.postData.likeCount = props.postData.likeCount + 1;
                    isRecentLike.value = true;
                }
            }
            isLikeSubmiting.value = false;
        }, 300) 

        const isSubmittingCollect = ref(false);

        const toggleCollect = debounce(async () => {
            if(isSubmittingCollect.value) {
                return 
            }
            isSubmittingCollect.value = true;
            const isCollocted = props.postData.hasCollect == 1;
            if(isCollocted) {
                const formData = new FormData();
                formData.append('paperid', props.postData.paperId);
             const res = await unCollectPost(formData);
             if(res.success) {
                message.info('已取消收藏');
                props.postData.hasCollect = 2;
             }
            }
            else {
                const formData = new FormData();
                formData.append('paperid', props.postData.paperId);
                const res = await collectPost(formData);
                if(res.success) {
                    message.success('成功收藏');
                    props.postData.hasCollect = 1;
                }
            }
            isSubmittingCollect.value = false;
        }, 300) 

        const handleCommentClick = () =>  {
            const href = $router.resolve({
                name: 'bbsPost',
                params: {
                    id: props.postData.paperId
                }
            });
            window.open(href.href + '#comment', '_blank')
        }

        /*****************************    设置接口对接      ************************/
        const settingVisible = ref(false);

        const isSubmittingSetting = ref(false);

        const setVisibleRange = async (val) => {
            if(props.postData.visibleRange == val ) {
                return ;
            }
            isSubmittingSetting.value = true;
            const formData = new FormData();

            formData.append('paperid', props.postData.paperId);
            formData.append('visibleRange',val )
        
            const res = await setPostVisibleRange(formData);
            if(res.success) {
                message.success('修改成功');
                props.postData.visibleRange = val;
            }
            isSubmittingSetting.value = false;

        }

        const setAllowedComment = async (val) => {
          const newVal = props.postData.allowedComment == 1 ? 2 : 1;
          const formData = new FormData();
          formData.append('paperid',props.postData.paperId);
          formData.append('allowedcomment', newVal);
          isSubmittingSetting.value = true;
          const res = await setPostIsAllowedComment(formData);
          if(res.success) {
            message.success('修改成功');
            props.postData.allowedComment = newVal
          }
          isSubmittingSetting.value = false;
        }

        // 根据关键词高亮
		const handleTextHighLight = (text, keyWord) => {
			if (!text) {
				return text;
			}
            if(!keyWord) {
                return text;
            }
			const newText = text.replace(new RegExp(keyWord,'g'), `<strong style="color: #f03535; margin: 0;"> ${keyWord}</strong>`);
			return `<span> ${newText} <span>`;
		}

        const getHightLightTitle = computed(() => {
            return handleTextHighLight(props.postData.title, props.keyWord);
        })

        const getHightLightSummary = computed(() => {
            return handleTextHighLight(props.postData.summary, props.keyWord);
        })
        return {
            handleDelete,
            isRecentLike,
            getThumbsClass,
            getUserHref,
            getPostHref,
            handlePostItemClick,
            handleToggleLike,
            toggleCollect,
            handleCommentClick,
            settingVisible,
            isSubmittingSetting,
            setVisibleRange,
            setAllowedComment,
            getHightLightTitle,
            getHightLightSummary
        }
    },
   /*  data() {
        return {
            like: false,
            isRecentLike: false,
            isCollocted: false,
        };
    },
    computed: {
        getThumbsClass() {
            if (this.like && this.isRecentLike) {
                return "recent-like";
            }
            return "";
        },
        getUserHref() {
            const href = this.$router.resolve({
                name: "bbsUser",
                params: {
                    id: "1234"
                }
            });
            return href.href;
        }
    },
    methods: {
        handlePostItemClick() {
            window.open("https://www.baidu.com/", "_blank");
        },
        handleCommentClick() {
            window.open("https://www.baidu.com/#comment", "_blank");
        },
        handleToggleCollection() {
            this.isCollocted = !this.isCollocted;
        }
    }, */
    components: { DefaultAvatar }
}
</script>

<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: none;
}

.post-item-ctn {
    padding: 20px;
    cursor: pointer;
    background: #fff;
    transition: .3s all ease-in-out;
    display: flex;
    align-items: center;
    border-bottom: .5px solid #ebebeb;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    .post-item-left-ctn {
        width: 0px;
        flex: 1;
        .post-item-header-ctn {
            display: flex;

            .text-ctn {
                margin-left: 10px;
            }

            .user-name-ctn {
                display: flex;
                align-items: flex-end;
                &:hover {
                    color: #597ef7;
                }
                .user-name {
                    color: rgba(0, 0, 0, 0.85);
                }

                .user-pos {
                    font-size: 12px;
                    color: #8590A6;
                    padding: 0px 8px;
                    margin-left: 16px;
                    position: relative;
                    z-index: 100;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        top: 2px;
                        left: 0px;
                        z-index: 10;
                        border-left: 0.5px solid #8590A6;
                        border-top: 0.5px solid #8590A6;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        bottom: 2px;
                        right: 0px;
                        z-index: 10;
                        border-right: 0.5px solid #8590A6;
                        border-bottom: 0.5px solid #8590A6;
                    }
                }

                .publish-date {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.45);
                    margin-top: 2px;
                }
            }

        }

        .post-item-title {
            a {
                color: rgba(0, 0, 0, 0.85);
            }

            margin-top: 16px;
            font-size: 18px;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .post-item-description {
            margin-top: 10px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);

        }
        .post-item-operation {
            margin-top: 20px;
            display: flex;
            .comment-btn {
                    margin-right: 20px;
                }
            .operation-ctn {
                display: flex;
                align-items: center;
                .operation-icon {
                    fill: #8590A6;
                    font-size: 16px;
                   &:focus {
                    outline: none;
                   }
                }
                .thumbs-up-inactive {
                    //transition: .3s all ease-in-out;
                    transform: rotate(0deg) translateY(0px);
                }

                .recent-like {
                    fill: @srims-primary-color;
                   
                    animation: thumbsUp .5s ease-in-out;
                }
                .thumbs-up-active {
                    fill: @srims-primary-color;
                }
                .operation-text {
                    color: #8590A6;
                    transition: .3s color ease-in-out;
                    margin-left: 5px;
                }
                .operation-text-active {
                    color: @srims-primary-color;
                    //transition: .5s color ease-in-out;
                }
                .chat-1-fill {
                    transition: .3s all ease-in-out;
                }
                .collected-icon {
                    fill: #FFC34D;
                }
            }
            .operation-ctn + .operation-ctn {
                margin-left: 20px;
            }
            .comment-operation,.setting-operation,.delete-operation {
                &:hover {
                    transition: .3s all ease-in-out;
                    .chat-1-fill {
                        fill: @srims-primary-color;
                    }
                    .operation-text {
                        color: @srims-primary-color;
                    }
                }
            }
        }
    }
    .post-item-right-ctn {
        margin-left: 41px;
        .post-item-cover {
            width: 180px;
            height: 108px;
            object-fit: cover;
        }
    }

}

@keyframes thumbsUp {
    0% {
        fill: #8590A6;
        transform: rotate(0deg) translateY(0px);
    }
    50% {
        transform: rotate(-30deg) translateY(-6px);
    }
    100% {
        fill: @srims-primary-color;
        transform: rotate(0deg) translateY(0px);
    }
}

.setting-item {
    cursor: pointer;
    &:hover {
        .setting-item-inner  {
            background: #f7f7f7;
        }
    }
    .setting-item-inner {
        display: flex;
        padding: 4px 10px;
        align-items: center;
    }
}
</style>